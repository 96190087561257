import React, {useState} from "react";
import axios from "axios";
import UserService from "../../services/user.service";
import {
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {BusAlert, Groups, MarkEmailUnread, PersonAdd, QrCode2, Rsvp, Send} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ResendEmailMenu = ({userId, registeredAt, presence, archived, setMessage}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, emailType: null});

    const handleToggleConfirmDialog = (emailType) => {
        //console.log("emailType:",emailType)
        setOpenConfirmDialog({
            ...openConfirmDialog,
            open: !openConfirmDialog.open,
            emailType: emailType
        })
        handleCloseUserMenu()
    }

    const handleConfirm = async () => {
        await handleSendEmailToUser()
    }

    const handleSendEmailToUser = async () => {
        if (openConfirmDialog.emailType === "QR") {
            await axios.get(UserService.userDataUrl(userId) + '/resend-qr')
                .then((res) => {
                    setMessage({show: true, text: "Invio effettuato", severity: "success"})
                    handleToggleConfirmDialog()
                })
                .catch(() => {
                    setMessage({
                        show: true,
                        text: "Invio non andato a buon fine",
                        severity: "error"
                    })
                })
        } else {
            axios.get(UserService.resendEmailToUserUrl(userId, openConfirmDialog.emailType),
                {
                    method: "GET"
                })
                .then(() => {
                    setMessage({show: true, text: "Invio effettuato", severity: "success"})
                    handleToggleConfirmDialog()
                })
                .catch((err) => {
                    setMessage({
                        show: true,
                        text: "Invio non andato a buon fine",
                        severity: "error"
                    })
                })
        }
    }


    return <>
        <Button
            id="basic-button"
            color={'accent'}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disabled={archived}
            onClick={handleOpenUserMenu}
            startIcon={<MarkEmailUnread/>}
        >
            Invia comunicazione
        </Button>
        <Menu id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseUserMenu}
              MenuListProps={{
                  'aria-labelledby': 'basic-button',
              }}>
            {!registeredAt &&
                <><MenuItem onClick={() => handleToggleConfirmDialog("SEND_INVITATION")}>
                <ListItemIcon>
                    <Rsvp/>
                </ListItemIcon>
                <ListItemText>{'Invia Invito'}</ListItemText>
                <Send fontSize="small" color={'accent'} sx={{marginLeft: 2}}/>
            </MenuItem>
            <Divider/></>}
            {<MenuItem onClick={() => handleToggleConfirmDialog("QR")} disabled={!presence}>
                    <ListItemIcon>
                        <QrCode2/>
                    </ListItemIcon>
                    <ListItemText>{'Invia QR Code + Convocazione'}</ListItemText>
                    <Send fontSize="small" color={'accent'} sx={{marginLeft: 2}}/>
            </MenuItem>}
        </Menu>
        <Dialog open={openConfirmDialog.open} onClose={() => handleToggleConfirmDialog(null)}>
            <DialogContent>
                <DialogContentText>
                    Sei sicuro di voler inviare la comunicazione all'utente?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <DialogActions>
                    <Button onClick={handleToggleConfirmDialog}>Annulla</Button>
                    <Button onClick={handleConfirm} variant={'submit'}
                            startIcon={<Send/>}>
                        {'Invia'}
                    </Button>
                </DialogActions>
            </DialogActions>
        </Dialog>
    </>;
}

export default ResendEmailMenu
