import React, {useMemo, useState} from "react";
import {Box, Fab, Paper, Typography} from "@mui/material";
import CustomTimelineChart from "../components/charts/CustomTimelineChart";
import useAxios from "axios-hooks";
import UserService from "../services/user.service";
import {filter, orderBy} from "lodash";
import CustomBackdrop from "../components/CustomBackdrop";
import CustomPageHeader from "../components/CustomPageHeader";
import {Refresh} from "@mui/icons-material";

const Home = () => {
    const [{data: allUsers, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl({all: true}), { method: "GET", useCache: false, manual: false }
    )

    const users = useMemo(() => {
        if(!allUsers)
            return null
        else return orderBy(allUsers.filter(function (o) {
            return true //!o.roles || o.test === true// && !o.test
        }), 'registeredAt._seconds', 'desc')
    }, [allUsers])
    return (
        <div>
            <CustomBackdrop open={loadingUsers}/>
            <CustomPageHeader title={'Andamento iscrizioni'} subtitle={'Descrizione grafico e overview'} refresh={fetchUsers}/>
            <Box px={2} py={1} my={1} display={'flex'} alignItems={'center'} component={Paper} variant={'outlined'}
                  textAlign={'left'}>
                <Box flex={1}>
                    <Typography>Utenti invitati totali</Typography>
                    <Typography variant={'h4'}>{users?.length || '-'}</Typography>
                </Box>
                <Box flex={1} justifyContent={'start'}>
                    <Typography>Utenti iscritti</Typography>
                    <Typography variant={'h4'}>{filter(users, function (o) {
                        //TODO: dinamizzare 'Non parteciperò'
                        return !!o.presence && o.presence !== 'no'
                    })?.length || '-'}</Typography>
                </Box>
                <Box flex={1}>
                    <Typography>Percentuale iscrizione</Typography>
                    <Typography variant={'h4'}>
                        {((((filter(users, function (o) {
                            return !!o.presence && o.presence !== 'no'
                        })?.length) * 100) / users?.length) || 0).toPrecision(3) || '-'} %
                    </Typography>
                </Box>
            </Box>
            <Box mt={1} p={2} component={Paper} variant={'outlined'} height={'450px'}>
                <CustomTimelineChart utenti={users} enableArea={true} timelineField={'registeredAt'}/>
            </Box>
            <Fab onClick={fetchUsers}
                 color={'primary'}
                 size={"medium"}
                 variant={'action'}>
                <Refresh/>
            </Fab>
        </div>
    )
}

export default Home