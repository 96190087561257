import React, {useEffect} from "react";
import _, {find} from "lodash";
import {fieldTypes} from "../services/utils";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slide,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {LocalizationProvider, MobileDateTimePicker, TimePicker} from "@mui/x-date-pickers";
import {
    Cancel,
    CheckCircle,
    ContentCopy,
    FiberManualRecord,
    OpenInNew,
    ScheduleSend,
    Send,
    VideoCameraFront
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import {API_URL} from "../config";
import CustomTooltip from "./CustomTooltip";
import {customTheme} from "../theme/customTheme";
import {useParams} from "react-router-dom";
import UserService from "../services/user.service";
import {CustomConfirmDialog} from "../services/columns.service";
import useAxios from "axios-hooks";
import ImgPopUpComponent from "./ImgPopUpComponent";

const CustomField = ({userId, formik, field, disabled}) => {
    const {id: user_id} = useParams()
    const fieldType = _.find(fieldTypes, ['type', field.type])

    const [{data: invitedBy, loading: loading, error: error}, fetchUser] = useAxios(
        UserService.userDataUrl(formik.values[field.id]), { method: "GET", useCache: false, manual: true }
    )

    useEffect(() => {
        if(field?.id === 'invitedBy' && formik?.values[field.id]) {
            fetchUser()
        }
    }, [])

    function getOptions() {
        switch (field.id) {
            case "luogoArrivo":
                return formik.values['mezzoDiTrasporto'] === "In aereo" ? ["Linate", "Malpensa"]
                    : formik.values['mezzoDiTrasporto'] === "In treno" ? ["Milano Centrale", "Milano Garibaldi"]
                        : (field?.values || [])
            default:
                return field?.values || []

        }
    }

    function getValue() {
        return field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]
    }

    function getOtherValue() {
        const val = getValue()
        switch (field.id) {
            case "luogoArrivo":
                return getOptions().includes(val) ? "" : val
            case "luogoPartenzaRitorno":
                return field.values.includes(val) ? "" : val
            default:
                return val

        }
    }

    //console.log("formik:",formik)

    return (
        <Slide in={true} exit={true}
               children={<Grid item xs={12} md={field?.cols?.md || fieldType?.md || 6}>
                   {(() => {
                       switch (fieldType) {
                           case fieldTypes.RADIO_GROUP:
                               return <FormControl fullWidth disabled={field.precompiled || disabled}>
                                   <Typography variant={'body2'}>{field.label || field.headerName}</Typography>
                                   <RadioGroup
                                       row
                                       value={field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                   >
                                       {
                                           (field.values || getOptions()).map((radio) =>
                                               <FormControlLabel key={radio.value} value={radio.value} control={
                                                   <Radio required/>} label={radio.label}/>
                                           )
                                       }
                                       {field.other
                                           && (field.condition && formik.values[field.condition.id] === field.condition.value)
                                           && <FormControlLabel
                                               checked={!getOptions().includes(getValue())}
                                               control={<Radio/>} label={<TextField
                                               name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               value={getOtherValue()}
                                               onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                               onBlur={formik.handleBlur}
                                               variant={"standard"} placeholder={'Altro:'}/>
                                           }/>}
                                   </RadioGroup>
                                   {/*field.id === 'presence' && (field.getter ?
                                           formik.values[field.getter][field.id] : formik.values[field.id]) === 'no'
                                       && <FormHelperText error sx={{maxWidth: '270px'}}>
                                           Modificando la partecipazione a "NO" verranno resettati i campi relativi alla
                                           logistica e ai documenti
                                       </FormHelperText>*/}
                               </FormControl>
                           case fieldTypes.CHECK_BOX:
                               return <FormControl>
                                   <FormGroup>
                                       <FormControlLabel
                                           control={<Checkbox
                                               id={field.id}
                                               name={field.id}
                                               disabled={field.precompiled || disabled}
                                               checked={formik.values[field.id]}
                                               //onChange={formik.handleChange}
                                               //onBlur={formik.handleBlur}
                                               onChange={(event, checked) => {
                                                   if (!field.precompiled && !disabled) {
                                                       formik.setFieldValue(field.id, checked)
                                                       formik.setFieldTouched(field.id)
                                                   }
                                               }}
                                           />} label={field.label}/>
                                   </FormGroup>
                               </FormControl>
                           case fieldTypes.SELECT:
                               const label = find(field.values, ['value', formik.values[field.id]])?.label

                               if (field.id === 'invitationStatus')
                                   return <>
                                       <Typography variant={'subtitle2'}>Stato dell'invito</Typography>
                                       <Stack direction={'row'} alignItems={'center'}>
                                           <Chip label={label} icon={<ScheduleSend color={'info'}/>}/>
                                           <CustomConfirmDialog label={'APPROVARE'} iconButton={
                                               <CustomTooltip title={'APPROVARE'} children={
                                                   <CheckCircle color={'success'}/>}/>}
                                                                confirmAction={
                                                                    () => {
                                                                        axios.post(API_URL + "send-advisor-invitation", {
                                                                            id: user_id
                                                                        }).then(() => window.location.reload())
                                                                    }}/>
                                           <CustomConfirmDialog label={'RIFIUTARE'} iconButton={
                                               <CustomTooltip title={'RIFIUTARE'} children={
                                                   <Cancel/>}/>}
                                                                confirmAction={
                                                                    () => {
                                                                        axios.put(UserService.userDataUrl(user_id), {
                                                                            invitationStatus: 'rejected'
                                                                        }).then(() => window.location.reload())
                                                                    }}
                                           />
                                       </Stack>
                                   </>
                               return <FormControl variant="standard" fullWidth>
                                   <FormHelperText>{field.label}</FormHelperText>
                                   <Select
                                       disabled={field.precompiled || disabled}
                                       label={field.label}
                                       id={field.id}
                                       name={field.id}
                                       //value={dati[field.id]}
                                       value={formik.values[field.id]}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       //error={Boolean(formik.errors[field.id])}
                                       //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                                       //helperText={field.label}
                                   >
                                       {(field?.values || getOptions()).map(v =>
                                           <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                                       )}
                                   </Select>
                               </FormControl>
                           case fieldTypes.DATE:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDatePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input" fullWidth
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           //console.log("formatMySqlDate:",formatMySqlDate(date, formik.values.startingTime))
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]?._seconds ?
                                           formik.values[field.id]._seconds * 1000
                                           : formik.values[field.id]
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy"/>
                               </LocalizationProvider>
                           case fieldTypes.DATE_TIME:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDateTimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input" fullWidth
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]?._seconds ?
                                           formik.values[field.id]._seconds * 1000
                                           : formik.values[field.id]
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy, HH:mm"/>
                               </LocalizationProvider>
                           case fieldTypes.TIME:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <TimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input"
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]) || ''}
                                       inputFormat="HH:mm"/>
                               </LocalizationProvider>
                           case fieldTypes.URL:
                               return formik.values[field.id] ?
                                   <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                       <CustomTooltip
                                           title={'Apri link in un\'altra finestra'}
                                           children={<Button href={field.getter ?
                                               formik.values[field.getter][field.id]
                                               : formik.values[field.id]}
                                                             target={'_blank'}
                                                             color={'accent'}
                                                             size={'small'}
                                                             variant={'contained'}
                                                             sx={{paddingBlock: 1, color: 'white'}}
                                                             onClick={(event) => event.stopPropagation()}
                                                             endIcon={<VideoCameraFront/>}>
                                               Link {field.id === "videoUrl" ? "al video" : "streaming"}
                                           </Button>}/>
                                       <CustomTooltip
                                           title={"Copia link"}
                                           children={<IconButton color={'primary'}
                                                                 onClick={() =>
                                                                     navigator.clipboard.writeText(field.getter
                                                                         ? formik.values[field.getter][field.id]
                                                                         : formik.values[field.id])
                                                                 }>
                                               <ContentCopy fontSize={'small'}/>
                                           </IconButton>}/>
                                   </Box> : ''
                           case fieldTypes.ACCESS_TYPE:
                               if (formik.values.presence === 'si') {
                                   return <Stack alignItems={'center'}>
                                       {formik.values.qrCodeUrl ?
                                           <Stack p={1} borderRadius={'1rem'} alignItems={'center'}
                                                  border={`1px dashed ${customTheme.palette.accent.main}`}>
                                               <img src={formik.values.qrCodeUrl}
                                                    style={{width: '150px'}}/>
                                               <Chip label={`Braccialetto: ${formik.values.color === 'red'? "ROSSO" : formik.values.color === 'blue'? "BLU" : 'Non assegnato'}`}
                                                     sx={{fontWeight: 'bold', fontSize: "1rem", background: formik.values.color, color: formik.values.color ? 'white' : 'inherit'}}
                                               />
                                           </Stack>
                                           : <Button variant={'contained'}
                                                     onClick={async (event) => {
                                                         event.stopPropagation()
                                                         await axios.get(API_URL + 'generate-qr-code/' + userId)
                                                             .then((res) => {
                                                                 window.location.reload()
                                                             })
                                                     }}>
                                               Genera QR code per questo utente
                                           </Button>
                                       }
                                   </Stack>
                               } else if (formik.values.presence === 'no') {
                                   return <Stack direction={"row"} spacing={1} justifyContent={'center'}>
                                       {/*<CustomTooltip title={"Apri link streaming"} children={
                                           <Button href={formik.values.urlStreaming
                                               || `https://<URL>/live/${userId}`}
                                               //href={`https://believetoachieve2023.canon.it/live/${userId}`}
                                                   target={'_blank'}
                                                   color={'accent'}
                                                   variant={'outlined'}
                                                   endIcon={<VideoCameraFront/>}
                                           >
                                               Link allo streaming
                                           </Button>
                                       }/>
                                       <CustomTooltip title={"Copia link streaming"} children={
                                           <IconButton
                                               color={'accent'}
                                               variant={'outlined'}
                                               onClick={async (event) => {
                                                   event.stopPropagation()
                                                   await navigator.clipboard.writeText(
                                                       `https://believetoachieve2023.canon.it/live/${userId}`)
                                               }}>
                                               <ContentCopy/>
                                           </IconButton>
                                       }/>*/}
                                   </Stack>
                               }
                               return null

                           case fieldTypes.TEXT_BOX:
                               return <TextField id={field.id}
                                                 name={field.id}
                                                 disabled={field.precompiled || disabled}
                                                 multiline={true}
                                                 minRows={2}
                                                 maxRows={3}
                                                 value={formik.values[field.id]}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"outlined"}
                                                 sx={{width: '100%'}}/>
                           case fieldTypes.TEXT:
                           case fieldTypes.NUMBER:
                               let val = null
                               if (field.id === 'id')
                                   val = user_id

                               return <TextField id={field.id}
                                                 name={field.id}
                                                 disabled={field.precompiled || disabled}
                                                 value={val || formik.values[field.id]}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"standard"}
                                                 sx={{width: '100%'}}/>

                           case fieldTypes.DIVIDER:
                               return <Divider
                                   children={field?.label && <Chip size={'small'} label={field?.label || ''}/>}/>
                           case fieldTypes.USER:
                               return <TextField id={field.id}
                                          name={field.id}
                                          disabled={true}
                                          value={`${invitedBy?.cognome || ''} ${invitedBy?.nome || ''}`}
                                          label={field.label}
                                          variant={"standard"}
                                          sx={{width: '100%'}}/>
                           case fieldTypes.CUSTOM:
                               const picsObjs = formik.values[field.id]
                               return <Stack direction={'row'} flexWrap={'wrap'} spacing={2} justifyContent={'center'}>
                                   {picsObjs?.map((pic, i) => (
                                       <Stack key={i} p={1} spacing={1} position={'relative'}>
                                           <Stack>
                                               <ImgPopUpComponent qrCodeUrl={pic.url} maxWidthPreview={'200px'}
                                                                  maxWidthImg={'100%'}/>
                                               <Stack sx={{position: 'absolute', top: 0, width: '100%', p: 1}} alignItems={'start'}>
                                                   <CustomTooltip title={"Apri in un'altra scheda"}
                                                                  children={<IconButton href={`${pic.url}`}
                                                                                        target={"_blank"}
                                                                                        download
                                                                                        size={'small'}
                                                                                        sx={{backgroundColor: 'accent.main', color: 'white'}}>
                                                                      <OpenInNew fontSize={'small'}/>
                                                                  </IconButton>}/>
                                               </Stack>
                                               <Chip size={'small'}
                                                     label={`Caricata alle: ${new Date((pic.date?._seconds * 1000)).toLocaleString('it-IT')}`}/>
                                           </Stack>
                                       </Stack>
                                   ))}
                               </Stack>
                           default:
                               return null
                       }
                   })()}
               </Grid>}
        />)
}

export default CustomField