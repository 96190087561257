import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBZc8slzM9GIU1qfNc-mA-Try5E4pqoptA",
    authDomain: "dash-axa-convention-2023.firebaseapp.com",
    projectId: "dash-axa-convention-2023",
    storageBucket: "dash-axa-convention-2023.appspot.com",
    messagingSenderId: "337685848424",
    appId: "1:337685848424:web:e542869db6743310b8b6dd"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}