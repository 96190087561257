import React, {useState} from "react";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    FormControlLabel,
    FormGroup, Radio, RadioGroup,
    TextField, Typography
} from "@mui/material";
import {Help, Info, Save} from "@mui/icons-material";
import {each, some} from "lodash";
import axios from "axios";
import UserService from "../../services/user.service";
import CustomTooltip from "../../components/CustomTooltip";
import IconButton from "@mui/material/IconButton";

const AddUserDialog = ({dialogState, handleClose, formId, setError}) => {
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [sendEmail, setSendEmail] = useState(false)
    const [userData, setUserData] = useState({
        email: '',
        nome: '',
        cognome: '',
        userType: 'agente',
        codiceAgenzia: '',
        nomeAgenzia: '',
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldId = event.target.id || event.target.name
        if(fieldId === 'email') {
            setIsValidEmail(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value))
        }

        setUserData({...userData, [fieldId]: event.target.value});
    };

    const handleSubmit = () => {
        axios({
            url: `${UserService.usersUrl({})}/${formId}?sendEmail=${sendEmail}`,
            method: 'POST',
            data: {...userData, email: userData.email.trim().toLowerCase()},
        })
            .then((res) => {
                if(res) {
                    setUserData({
                        email: '',
                        nome: '',
                        cognome: '',
                        userType: 'agente',
                        codiceAgenzia: '',
                        nomeAgenzia: '',
                    })
                    handleClose({status: 'success', userId: res.data})
                }
            })
            .catch((err) => {
                setError(err.response?.data?.error || "Inserimento non andato a buon fine")
                console.log("handleSubmit new user --> err:", err)
            })
    }

    function isFormNotValid() {
        return (some(Object.entries(userData), ([key, value], i) => {
            return (key !== 'codiceAgenzia' && key !== 'nomeAgenzia') ? !value : false
        }));
    }

    return (
        <Dialog open={dialogState.open} onClose={handleClose} maxWidth={'xs'} fullWidth
                PaperProps={{variant: 'dialog'}} scroll={'paper'}>
            <DialogTitle>{'Aggiungi un nuovo utente'}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{mt: 2}}>
                    <Typography variant={'body2'}>Tipo di utente invitato</Typography>
                    <RadioGroup
                        row
                        value={userData.userType}
                        defaultValue={'agente'}
                        onChange={handleChange}
                        name={'userType'}
                        id={'userType'}
                    >
                        <FormControlLabel value={'agente'} control={
                            <Radio required/>} label={'Agente'}/>
                        <FormControlLabel value={'advisor'} control={
                            <Radio required/>} label={'Advisor'}/>
                    </RadioGroup>
                </FormControl>
                <TextField
                    autoFocus
                    margin="dense"
                    id="nome"
                    label="Nome"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    value={userData.nome}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="cognome"
                    label="Cognome"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    value={userData.cognome}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="E-mail"
                    type="email"
                    fullWidth
                    variant="standard"
                    required
                    value={userData.email}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="nomeAgenzia"
                    label="Nome Agenzia"
                    fullWidth
                    variant="standard"
                    value={userData.nomeAgenzia}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="codiceAgenzia"
                    label="Codice Agenzia"
                    fullWidth
                    variant="standard"
                    value={userData.codiceAgenzia}
                    onChange={handleChange}
                />
                <FormControl sx={{mt: 2}}>
                    <FormGroup row sx={{alignItems: 'center'}}>
                        <FormControlLabel
                            control={<Checkbox
                                id={'invio-email'}
                                name={'invio-email'}
                                checked={sendEmail}
                                onChange={(event, checked) => {
                                    setSendEmail(checked)
                                }}
                            />} label={'Invia email di registrazione'}/>
                        <CustomTooltip title={"Verrà inviata un'email automatica per permettere la registrazione dell'utente inserito"}
                                       children={<IconButton size={'small'}><Help color={'disabled'} fontSize={'small'}/></IconButton>}/>
                    </FormGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant={'submit'} startIcon={<Save/>}
                        disabled={!isValidEmail || isFormNotValid()}
                        onClick={handleSubmit}>
                    Aggiungi
                </Button>

            </DialogActions>
        </Dialog>
    );
}

export default AddUserDialog