import React, {useContext, useState} from "react";
import {Link, useLocation, useParams} from 'react-router-dom'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    alpha,
    Box,
    Button,
    Card,
    Chip,
    Container,
    Fab,
    Grid,
    Skeleton,
    Snackbar,
    Typography
} from "@mui/material";
import UserService from "../../services/user.service";
import {Archive, ArrowBackIosNew, ExpandMore, LocalOffer, Save} from "@mui/icons-material";
import {Alert} from "@mui/lab";
import useAxios from "axios-hooks";
import CustomField from "../../components/CustomField";
import {useFormik} from "formik";
import _, {filter, find, isEmpty, replace} from 'lodash'
import CustomizedTimeline from "../../components/CustomTimeline";
import {APP_BAR_HEIGHT, customTheme} from "../../theme/customTheme";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomPageHeader from "../../components/CustomPageHeader";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../../components/CustomTooltip";
import {GlobalContext} from "../../state/global";
import UserDetailsMenu from "./UserDetailsMenu";
import {fieldTypes} from "../../services/utils";
import ResendEmailMenu from "./ResendEmailMenu";

const UserDetails = () => {
    const [globalState, dispatch] = useContext(GlobalContext)

    const {id} = useParams() //dc402b1dcaea136215f9b588a25c76ec
    const location = useLocation()

    const activeFormId = location?.state?.activeFormId || find(globalState.dataStructures, ['main', true]) || 'iscrizioni'

    const [message, setMessage] = useState({show: false, text: "", severity: "success"})

    const [{data: userData, loading: loadingUserData, error: errorUserData}, fetchUser] = useAxios(
        UserService.userDataUrl(id), {method: "GET", useCache: false}
    )

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };

    const formik = useFormik({
        initialValues: userData,
        enableReinitialize: true,
        //validationSchema: // TODO
    });

    const isValid = () => {
        return formik.dirty && formik.isValid
    }

    const handleSubmitForm = () => {
        //console.log('formik:',formik)
        if (isValid()) {
            //console.log("formik:",formik)
            const dto = {}
            Object.keys(formik.touched).forEach((touchedField) => {
                if (formik.values[touchedField] !== formik.initialValues[touchedField]) {
                    if (touchedField === 'note') {
                        dto[touchedField] = replace(replace(formik.values[touchedField].trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
                    } else dto[touchedField] = formik.values[touchedField]
                }
            })

            //console.log("dto:", dto)
            if (!_.isEmpty(dto)) {
                updateUser({
                    data: {data: dto},
                    url: UserService.userDataUrl(id),
                    method: "PUT"
                })
                    .then(() => {
                        setMessage({
                            ...message,
                            show: true,
                            text: "Aggiornamento effettuato con successo",
                            severity: "success"
                        })
                        fetchUser()
                    })
                    .catch((err) => {
                        setMessage({
                            ...message,
                            show: true,
                            text: "Aggiornamento non andato a buon fine",
                            severity: "error"
                        })
                    })
            }
        }
    }

    return (
        <main>
            <CustomBackdrop open={loadingUserData || loadingUpdate}/>
            <Box display={'flex'} alignItems={'center'}
                 position={'sticky'}
                 top={APP_BAR_HEIGHT * 2}
                 sx={{zIndex: 1, backgroundColor: 'white'}}
                 pb={1}
            >
                <CustomTooltip
                    title={'Torna all\'elenco'}
                    children={<IconButton color={'accent'} LinkComponent={Link} to={"/iscrizioni"}>
                        <ArrowBackIosNew/>
                    </IconButton>}/>
                {userData ?
                    <CustomPageHeader title={`${userData?.nome || ''} ${userData?.cognome || ''}`}
                                      subtitle={'Dati utente'}
                                      refresh={fetchUser} gutterBottom={false}/>
                    : loadingUserData ?
                        <Skeleton variant="text" sx={{fontSize: '1.5rem'}} animation={"wave"} width={'20%'}/>
                        : null}
                {userData?.archived &&
                    <Chip variant={'accent-outlined'}
                          label={'UTENTE ARCHIVIATO'}
                          size={'small'}
                          sx={{ml: 1}}
                          icon={<Archive fontSize={'small'} color={"accent"}/>}/>}
                <Box marginLeft={'auto'}>
                    {!!userData?.registeredAt &&
                        <Chip sx={{mr: 1}}
                              label={`Utente registrato il ${new Date(userData?.registeredAt?._seconds * 1000).toLocaleDateString()}`}/>
                    }
                    <ResendEmailMenu userId={id} registeredAt={userData?.registeredAt} archived={userData?.archived}
                                     presence={(userData?.presence === 'si')}
                                     setMessage={setMessage}/>
                    <Button
                        color={'accent'}
                        variant={'outlined'}
                        onClick={handleSubmitForm}
                        disabled={!isValid()}
                        startIcon={<Save/>}
                    >
                        SALVA
                    </Button>
                    <UserDetailsMenu userId={id} updateUser={updateUser} archive={!userData?.archived}
                                     onSuccess={() => {
                                         setMessage({
                                             show: true,
                                             text: "Aggiornamento effettuato con successo",
                                             severity: "success"
                                         })
                                         fetchUser()
                                     }}
                                     onError={() => setMessage({
                                         show: true,
                                         text: "Aggiornamento non andato a buon fine",
                                         severity: "error"
                                     })}/>
                </Box>
            </Box>
            <Container>
                <form onSubmit={handleSubmitForm}>
                    {userData?.mailchimp &&
                        <Accordion defaultExpanded={false}
                                   sx={{background: alpha(customTheme.palette.primary.main, 0.2), borderRadius: '4px'}}>
                            <AccordionSummary expandIcon={<ExpandMore/>} sx={{alignItems: 'center'}}>
                                <Typography variant={"h6"} textAlign={'center'}>
                                    Attività su Mailchimp
                                </Typography>
                                <Chip label={`${filter(userData.mailchimp?.activity, function (o) {
                                    return Object.keys(o).length > 0
                                })?.length || 0} azioni`}
                                      color={'primary'}
                                      variant={'outlined'}
                                      size={'small'}
                                      sx={{margin: 0.5, marginLeft: 1}}
                                />
                                {userData?.mailchimpTags &&
                                    userData?.mailchimpTags?.tags?.map((tag, i) => (
                                        <Chip key={i}
                                              label={`${tag.name}`}
                                              color={'primary'}
                                              size={'small'}
                                              sx={{margin: 0.5}}
                                              icon={<LocalOffer/>}
                                        />
                                    ))
                                }

                            </AccordionSummary>
                            <AccordionDetails>
                                <CustomizedTimeline mailchimpActivity={userData.mailchimp?.activity}/>
                            </AccordionDetails>
                        </Accordion>
                    }
                    {
                        globalState.dataStructures ?
                            globalState.dataStructures[activeFormId]?.sections?.map((section, i) => {
                                if (userData && formik.values) {
                                    if (!isEmpty(section.fields)) {
                                        return <Card key={i} variant={'outlined'}
                                                     sx={{
                                                         padding: '1rem',
                                                         margin: '0.5rem 0',
                                                         boxShadow: '1px 1px 6px 0px rgb(140 144 146 / 15%)'
                                                     }}>
                                            <Typography color={customTheme.palette.primary.main} variant={"h6"}
                                                        sx={{textAlign: 'left'}}>{section.title}</Typography>
                                            <Grid container spacing={3}>
                                                {
                                                    filter(section.fields, function (o) {
                                                        const field = find(fieldTypes, ['type', o.type])
                                                        return !field?.onlyUserForm
                                                    }).map((field, i) => {
                                                        if (!!field.conditions) {
                                                            const res = field.conditions.type === 'or' ?
                                                                _.some(field.conditions.predicates, function (o) {
                                                                    return formik.values[o.id] === o.value
                                                                }) : field.conditions.type === 'and' ?
                                                                    _.every(field.conditions.predicates, function (o) {
                                                                        return formik.values[o.id] === o.value
                                                                    }) : null
                                                            if (res)
                                                                return <CustomField key={i} formik={formik}
                                                                                    field={field}
                                                                                    userId={id}
                                                                                    disabled={!section.editable
                                                                                        || userData.archived}/>
                                                        } else if (!(field.condition
                                                            && formik.values[field.condition.id] !== field.condition.value))
                                                            return <CustomField key={i} formik={formik} field={field}
                                                                                userId={id}
                                                                                disabled={!section.editable
                                                                                    || userData.archived}/>
                                                    })
                                                }
                                            </Grid>
                                        </Card>
                                    }
                                } else {
                                    return <Skeleton key={i}
                                                     variant="rounded"
                                                     width={'100%'} height={'110px'}
                                                     animation={"wave"}
                                                     sx={{marginBottom: 1}}/>
                                }
                            })
                            : userData
                            && formik.values
                            && globalState.dataStructures ?
                                <Card sx={{padding: '1rem', margin: '0.5rem 0'}}
                                      variant={'outlined'}>
                                    <Typography color={customTheme.palette.primary.main} variant={"h6"}
                                                sx={{textAlign: 'left'}}>{globalState.dataStructures[activeFormId]?.title}</Typography>
                                    <Grid container spacing={3}>
                                        {
                                            (globalState.dataStructures[activeFormId]?.fields || []).map((field, i) => {
                                                if (!!field.conditions) {
                                                    const res = _.some(field.conditions, function (o) {
                                                        return formik.values[o.id] === o.value
                                                    })
                                                    if (res)
                                                        return <CustomField key={i} formik={formik} field={field}
                                                                            userId={id}
                                                                            disabled={!globalState.dataStructures[activeFormId].editable}
                                                        />
                                                } else if (!(field.condition
                                                    && formik.values[field.condition.id] !== field.condition.value))
                                                    return <CustomField key={i} formik={formik} field={field} userId={id}
                                                                        disabled={!globalState.dataStructures[activeFormId].editable}
                                                    />
                                            })
                                        }
                                    </Grid>
                                </Card>
                                : <Skeleton variant="rounded"
                                            width={'100%'} height={'110px'}
                                            animation={"wave"}
                                            sx={{marginBottom: 1}}/>
                    }
                    <Fab onClick={handleSubmitForm}
                         disabled={!isValid()}
                         color={'primary'}
                         size={'medium'}
                         variant={'action'}
                    >
                        <Save/>
                    </Fab>
                </form>
            </Container>
            <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert} severity={message.severity}
                       sx={{width: '100%'}}>
                    {message.text}
                </Alert>
            </Snackbar>
        </main>
    )
}

export default UserDetails
