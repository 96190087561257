import React, {useState} from "react";
import _, {filter, find, map} from 'lodash'
import {fieldTypes, parseMultiLineTextBox} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Stack,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../components/CustomTooltip";
import ImgPopUpComponent from "../components/ImgPopUpComponent";
import {
    Cancel,
    CancelScheduleSend,
    Check, CheckCircle,
    Close,
    ContentCopy, FiberManualRecord,
    Link, Logout,
    MarkEmailRead,
    ScheduleSend,
    VideoCameraFront
} from "@mui/icons-material";
import UserService from "./user.service";

const columnsUrl = (confId) => {
    return `${API_URL}config/columns/${confId || ''}`
}

const saveColumsToExport = (confId, columns) => {
    return axios.post(`${columnsUrl()}export`, {[confId]: columns},)
};

const saveDefaultVisibilityModel = (formId, columns) => {
    return axios.post(`${columnsUrl()}visibility`, {[formId]: {defaultVisibilityModel: columns}},)
};

const getFieldsFromForm = (form) => {
    let fields = []
    if (form) {
        if (form.sections) {
            form.sections.forEach((section) => {
                fields = fields.concat(map(section.fields, (field) => ({...field, section: section.title})))
            })
            return fields
        } else {
            return form.fields
        }
    }
}

export function CustomConfirmDialog({label, iconButton, confirmAction}) {
    const [open, setOpen] = useState(false)

    const handleConfirm = () => {
        confirmAction()
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <IconButton size={'small'} onClick={(event) => {
                event.stopPropagation()
                setOpen(!open)
            }}>
                {iconButton}
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>
                        <Stack direction={'row'} alignItems={'center'} spacing={3}>
                            Sei sicuro di {label}?
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DialogActions>
                        <Button onClick={handleClose}>Annulla</Button>
                        <Button onClick={handleConfirm} variant={'submit'}
                                startIcon={iconButton}>{label}
                        </Button>
                    </DialogActions>
                </DialogActions>
            </Dialog>
        </>
    );
}

const getColumns = (form, {users: users, refresh: refresh}) => {
    const cols = filter(getFieldsFromForm(form), function (o) {
        const field = find(fieldTypes, ['type', o.type])
        return !field?.onlyUserForm && !field?.noDataGrid
    })?.map((field) => {
        let baseField = {
            field: field.id || field,
            headerName: field.headerName || field.label || field.id || (_.startCase(field)),
            flex: 1,
        }

        baseField = field.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return params.row[field.getter]?.[field.id]?._seconds ?
                    new Date(params.row[field.getter]?.[field.id]?._seconds * 1000)
                    : params.row[field.getter]?.[field.id]
            }
        } : {
            ...baseField, valueGetter: (params) => {
                return params.row[field.id]?._seconds ?
                    new Date(params.row[field.id]?._seconds * 1000)
                    : params.row[field.id]
            }
        }

        const formType = _.find(fieldTypes, ['type', field.type])
        if (!formType?.onlyUserForm) {
            switch (formType) {
                case fieldTypes.DATE:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleDateString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.DATE_TIME:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.TIME:
                    return {
                        ...baseField,
                        type: 'dateTime', // TODO: capire
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleTimeString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.CHECK_BOX:
                    return {
                        ...baseField,
                        flex: 0.6,
                        valueGetter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'si';
                            } else if (params.value === false || params.value === 'no')
                                return 'no';
                            else return params.value || '-'
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no')
                                return 'No';
                            else return 'Non specificato'
                        },
                        renderCell: (params) => (params.value === true || params.value === 'si' || params.value === 'sì')
                            ? <Check sx={{color: 'green'}}/>
                            : (params.value === false || params.value === 'no' || params.value === '')
                                ? <Close color={'disabled'}/>
                                : '-'
                    }
                case fieldTypes.URL:
                    return {
                        ...baseField,
                        renderCell: (params) => params.value ?
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <CustomTooltip title={"Copia link"}
                                               children={<IconButton size={'small'} color={'primary'}
                                                                     onClick={(event) => {
                                                                         event.stopPropagation()
                                                                         navigator.clipboard.writeText(params.value)
                                                                         //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))

                                                                     }}>
                                                   <ContentCopy fontSize={'small'}/>
                                               </IconButton>}
                                />
                                <CustomTooltip
                                    title={'Apri link in un\'altra finestra'}
                                    children={<Button href={params.value}
                                                      target={'_blank'}
                                                      color={'accent'}
                                                      size={'small'}
                                                      variant={'contained'}
                                                      sx={{color: 'white'}}
                                                      onClick={(event) => event.stopPropagation()}
                                                      endIcon={<VideoCameraFront/>}>
                                        Link
                                    </Button>}/>
                            </Box> : ""
                    }
                case fieldTypes.COLOR:
                    return {
                        ...baseField,
                        renderCell: (params) => params.value ?
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <FiberManualRecord sx={{color: params.value, fontSize: '40px'}}/>
                                <Typography>{params.value === 'red' ? "ROSSO" : params.value === 'blue' ? "BLU" : ''}</Typography>
                            </Box> : "-"
                    }
                case fieldTypes.ACCESS_TYPE:
                    return {
                        ...baseField,
                        disableExport: true,
                        renderCell: (params) => {
                            /** CAMBIARE streaming */
                            if (params.row.presence === 'streaming')
                                return <CustomTooltip title={"Copia link streaming"} children={
                                    <Button
                                        color={'accent'}
                                        onClick={async (event) => {
                                            event.stopPropagation()
                                            await navigator.clipboard.writeText(`https://believetoachieve2023.canon.it/live/${params.id}`)
                                        }}>
                                        <Link/>
                                    </Button>
                                }/>
                            else if (params.row.presence === 'si')
                                return !!params.row.qrCodeUrl ?
                                    <ImgPopUpComponent qrCodeUrl={params.row.qrCodeUrl}/>
                                    : <Chip label={'No QR Code'} size={'small'}/>
                            else if (params.row.presence === 'no')
                                return '-'
                        }
                    }
                case fieldTypes.RADIO_GROUP:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        valueGetter: (params) => {
                            if (params.value === 'si') {
                                return 'si';
                            } else if (params.value === 'no')
                                return 'no';
                            else {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna delle precedenti" ? label : params.value
                            }
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì' || params.value === 'partecipo') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no' || params.value === 'nonPartecipo')
                                return 'No';
                            else {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna delle precedenti" ? label : params.value
                            }
                        },
                        renderCell: (params) => {
                            return (params.value === true
                                || params.value?.toLowerCase() === 'si' || params.value?.toLowerCase() === 'sì'
                                || params.value === 'partecipo')
                                ? <Stack direction={'row'} spacing={1}><Check sx={{color: 'green'}}/></Stack>
                                : (params.value === false || params.value?.toLowerCase() === 'no' || params.value === 'nonPartecipo')
                                    ? <Stack direction={'row'} spacing={1}><Close color={'disabled'}/></Stack>
                                    : (find(field.values, ['value', params.value])?.label || params.value || '-')
                        }
                    }
                case fieldTypes.SELECT:
                    if (field.id === 'invitationStatus') {
                        return {
                            ...baseField,
                            flex: 2,
                            renderCell: (params) => {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna" ?
                                    params.value !== 'pending' ? <Chip label={label}
                                                                       icon={params.value === 'rejected' ?
                                                                           <CancelScheduleSend color={'error'}/>
                                                                           : params.value === 'invited' ?
                                                                               <MarkEmailRead color={'success'}/>
                                                                               : 'inherit'}/>
                                        :
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <Chip label={label} icon={<ScheduleSend color={'info'}/>}/>
                                            <CustomConfirmDialog label={'APPROVARE'} iconButton={
                                                <CustomTooltip title={'APPROVARE'} children={
                                                        <CheckCircle color={'success'}/>}/>}
                                                                 confirmAction={
                                                                     () => {
                                                                         axios.post(API_URL + "send-advisor-invitation", {
                                                                             id: params.row.id
                                                                         }).then(() => refresh())
                                                                     }}/>
                                            <CustomConfirmDialog label={'RIFIUTARE'} iconButton={
                                                <CustomTooltip title={'RIFIUTARE'} children={
                                                        <Cancel/>}/>}
                                                                 confirmAction={
                                                                     () => {
                                                                         axios.put(UserService.userDataUrl(params.row.id), {
                                                                             invitationStatus: 'rejected'
                                                                         }).then(() => refresh())
                                                                     }}
                                            />
                                        </Stack>
                                    : params.value
                            },
                            valueFormatter: (params) => {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna" ? label : params.value
                            },
                        }
                    }
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            const label = find(field.values, ['value', params.value])?.label
                            return label && label !== "Nessuna" ? label : params.value
                        },
                    }
                case fieldTypes.TEXT_BOX:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            return parseMultiLineTextBox(params.value)
                        },
                    }
                case fieldTypes.USER:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            const user = find(users, ['id', params.value])
                            return `${user.cognome||''} ${user.nome||''}`
                        },
                    }
                case fieldTypes.CUSTOM:
                    return {
                        ...baseField,
                        renderCell: (params) => {
                            const max_imgs = 3
                            const picsObjs = params.value
                            const leftImgs = picsObjs?.length - max_imgs

                            return <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'}>
                                {picsObjs?.slice(0,max_imgs)?.map((pic, i) => (
                                    <ImgPopUpComponent key={i} qrCodeUrl={pic.url} maxWidthImg={'100%'}/>))}
                                {leftImgs > 0 && <Chip label={`+${leftImgs}`} size={'small'} variant={'accent-outlined'}/>}
                            </Stack>
                        }
                    }
                default:
                    return baseField
            }
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumns,
    saveColumsToExport,
    saveDefaultVisibilityModel,
    getFieldsFromForm
};

export default ColumnsService;


export const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    }
]